export class Rent {
    constructor () {
        this.rentFilterMap();
        this.priceRange();
        this.modalDialog();

        this.rentalApplicationDialogSteps();
    };

    rentFilterMap () {
        let target = null;
        $(".rent-filter-map a").on("click", function (e) {
            let curTarget;
            if ($(e.target).prop("tagName") !== "A") {
                curTarget = $(e.target).parent().attr("data-target");
            } else {
                curTarget = $(e.target).attr("data-target");
            }
            if (target === curTarget) {
                $(curTarget).removeClass("active");
                $(e.target).parent().removeClass("active");
                target = null;
                e.stopPropagation();
            } else {
                $(curTarget).addClass("active");
                target = curTarget;
            }
        });
    };

    changePriceRange () {
        let values = (<String>$("#price-range").val()).split(",");
        let html = "$" + values[0] + " - $" + values[1];
        $("#priceRange").html(html);
    };

    priceRange () {
        let priceRange: any = $("#price-range");
        let value = priceRange.attr("data-value");
        let minPrice = +priceRange.attr("min");
        let maxPrice = +priceRange.attr("max");

        if (value && value !== "") {
            value = value.split(",");
            for (let i = 0; i < value.length; i++) {
                value[i] = parseInt(value[i]);
            }
        } else {
            value = null;
        }
        priceRange.slider({
            id: "slider-price-range",
            min: minPrice,
            max: maxPrice,
            range: true,
            value: value || [minPrice, maxPrice]
        });

        priceRange.on( "slide", ( event, ui ) => {
            this.changePriceRange();
        } );

        if (priceRange.length > 0) {
            this.changePriceRange();
        }
    };

    modalDialog () {
        $(".modal-dialog").on("shown.bs.modal", function () {
            (<any>$(".datepicker input")).datepicker({
                startDate: "-3d"
            });
        });
    };

    rentalApplicationDialogSteps () {
        let stepContents = document.querySelectorAll("#rentalApplication .menu-body>.step");
        for (let i = 0; i < stepContents.length; i++) {
            $(stepContents[i]).addClass("hide");
        }

        let listSteps = document.querySelectorAll("#rentalApplication li.list-group-item");

        $(stepContents[0]).removeClass("hide");
        $(listSteps[0]).addClass("active-item");

        for (let i = 0; i < listSteps.length; i++) {
            listSteps[i].addEventListener("click", function (e) {
                let liElem = $(e.target).is("li") ? $(e.target) : $(e.target).parent();

                let allLiElem = liElem.parent().find("li");
                let passedFlag = true;
                for (let j = allLiElem.length; j > -1; j--) {
                    let currLiElem = $(allLiElem[j]);
                    currLiElem.removeClass("passed-item");
                    currLiElem.removeClass("active-item");
                    $(stepContents[j]).addClass("hide");

                    if (currLiElem.html() === liElem.html()) {
                        currLiElem.addClass("active-item");
                        $(stepContents[j]).removeClass("hide");
                        passedFlag = false;
                    } else if (!passedFlag) {
                        currLiElem.addClass("passed-item");
                    }
                }
            });
        }
    }
}