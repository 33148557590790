/// <reference path="./modules/slider.ts"/>

import "jquery";
declare let google: any;
// import "../../../web/assets/js/bundle.js";
import {Gmap} from "./modules/gmap/gmap";

import {Slider} from "./modules/slider";
import {ContactMap} from "./modules/contact-map";
import {Rent} from "./modules/rent/rent";
import {Common} from "./modules/common";
import {Pjax} from "./modules/pjax";

$(function () {
    new Slider();
    new ContactMap();
    let rent = new Rent();
    let pjax = new Pjax(rent);
    new Common(pjax.requestForms);
});
google.maps.event.addDomListener(window, "load", Gmap.init);
