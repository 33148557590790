export class ContactMap {
    constructor () {
        $(".hero-map-tabs span").on("click", (e) => {
            let southamptonId = "southampton";
            let elem = $(".hero-map-tabs span");
            for (let i = 0; i < elem.length; i++) {
                if (e.target === elem[i]) {
                    $(elem[i]).addClass("active");
                    this.changeInfoText($(elem[i]).attr("id") === southamptonId, true);
                } else {
                    $(elem[i]).removeClass("active");
                    this.changeInfoText($(elem[i]).attr("id") === southamptonId, false);
                }
            }
        });
    };

    changeInfoText (isSouthampton, isActive) {
        let paloAltoInfo = $("#palo-alto-text-info");
        let sauthamptonInfo = $("#sauthampton-text-info");
        let changingElement = isSouthampton ? sauthamptonInfo : paloAltoInfo;

        if (isActive) {
            changingElement.removeClass("hide");
        } else {
            changingElement.addClass("hide");
        }
    };
}