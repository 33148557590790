export class Common {
    requestForms: Object;
    constructor(_requestForms: Object) {
        this.requestForms = _requestForms;
        this.applyModalDialogInit();
    };

    applyModalDialogInit () {
        let dialogId = "#rentalApplicationSmall";
        let formContainerId = "pjax-request-container";
        let me = this;
        $(document).on("click", "button[data-target='" + dialogId + "']", function() {
            if (me.requestForms[formContainerId] && me.requestForms[formContainerId] !== "") {
                $(dialogId).find("#" + formContainerId).html(me.requestForms[formContainerId]);
            }
            let id = $(this).data("id");
            let selector = $(dialogId).find(".modal-body form");
            selector.find("input[name='RentRequest[property_id]']").val(id);
        });
    };
}