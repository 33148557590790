import {Rent} from "./rent/rent";
import {Gmap} from "./gmap/gmap";
export interface FaLoadMoreClasses {
    faPulse: string;
    faUndo: string;
    faSpinner: string;
}

export class Pjax {
    get requestForms(): Object {
        return this._requestForms;
    }
    private location: string;
    private isClickedClassString: string;
    private faLoadMoreClasses: FaLoadMoreClasses;
    private _requestForms: Object;
    private static pjaxLoadMoreForm: string = "pjax-rent-container";
    constructor (private rent: Rent) {
        this.faLoadMoreClasses = {
            faPulse: "fa-pulse",
            faUndo: "fa-undo",
            faSpinner: "fa-spinner"
        };

        this.isClickedClassString = "clicked";
        this._requestForms = {};
        this.pjaxInit("pjax-contacts-container", false);
        this.pjaxInit("pjax-request-container", true);
        this.pjaxInit(Pjax.pjaxLoadMoreForm, false);
        this.pjaxActions();
    };

    pjaxInit (containerId, isSaveInitForm) {
        if (isSaveInitForm) {
            this._requestForms[containerId] = $("#" + containerId).html();
        }
        containerId = "#" + containerId;
        $(document).on("submit", containerId + " form[data-pjax]", (event) => {
            this.location = window.location.pathname + window.location.search;
            let loadMoreId = "#load-more";
            let loadMoreButton = $(event.target).find("#load-more button.pseudo-link");
            let isLoadMore = loadMoreButton.hasClass(this.isClickedClassString);
            loadMoreButton.removeClass(this.isClickedClassString);
            // loadMoreButton.find("." + this.faLoadMoreClasses.faSpinner).addClass(this.faLoadMoreClasses.faUndo)
            //    .removeClass(this.faLoadMoreClasses.faPulse).removeClass(this.faLoadMoreClasses.faSpinner);
            // event.currentTarget.id === loadMoreId;
            $.pjax.submit(<any> event, containerId, {
                "timeout": 5000,
                // "scrollTo": $(containerId).offset().top
                "scrollTo": isLoadMore ? $(loadMoreId).offset().top : $(containerId).offset().top
            });
        });
    };

    getUrlParameter (sParam) {
        let sPageURL = decodeURIComponent(window.location.search.substring(1)),
            sURLVariables = sPageURL.split("&"),
            sParameterName,
            i;

        for (i = 0; i < sURLVariables.length; i++) {
            sParameterName = sURLVariables[i].split("=");

            if (sParameterName[0] === sParam) {
                return sParameterName[1] === undefined ? true : sParameterName[1];
            }
        }
    };

    buttonActionInitialization() {
        let sortBySelect = $("#sort-by-select");
        if (sortBySelect.length > 0) {
            if (this.getUrlParameter("sort")) {
                sortBySelect.attr("data-value", this.getUrlParameter("sort"));
                sortBySelect.selectpicker("val", sortBySelect.attr("data-value"));
            }
            sortBySelect.on("change", function () {
                $(this).closest("form").trigger("submit");
            });
        }

        let resetForm = $(`#${Pjax.pjaxLoadMoreForm} form[data-pjax]`);
        if (resetForm.length > 0) {
            resetForm.on("reset", (event) => {
                let sortString = "sort";
                let loadMoreString = "Property[propertyPageSize]";
                let sortParam = this.getUrlParameter(sortString);
                let loadMoreParam = this.getUrlParameter(loadMoreString);
                window.location.assign(window.location.origin + window.location.pathname + "?" +
                    sortString + "=" + sortParam + "&" + loadMoreString + "=" + loadMoreParam);
            });
            let me = this;
            resetForm.find("#load-more button.pseudo-link").on("click", function () {
                $(this).addClass(me.isClickedClassString);
                $(this).find("." + me.faLoadMoreClasses.faUndo).addClass(me.faLoadMoreClasses.faSpinner)
                    .addClass(me.faLoadMoreClasses.faPulse).removeClass(me.faLoadMoreClasses.faUndo);
            });
        }
    };

    reinitializeUiModules (form: any) {
        let selectPicker = form.find(".selectpicker");
        for (let i = 0; i < selectPicker.length; i ++) {
            $(selectPicker[i]).selectpicker("val", $(selectPicker[i]).attr("data-value"));
        }
        this.rent.priceRange();
    };

    pjaxActions () {
        this.buttonActionInitialization();
        $(document).on("pjax:success", (event, data) => {
            this.buttonActionInitialization();

            // reinitialize gmap.
            Gmap.initRentMap(null);
            if (event.target.id !== Pjax.pjaxLoadMoreForm) {
                history.pushState("", "", this.location);
                console.log(this.location);
            }
            let form = $(event.target).find("form[data-pjax]");
            this.reinitializeUiModules(form);

            if (form.attr("data-id")) {
                setTimeout(() => {
                    $(".modal:visible").modal("hide");
                    let inputs = form.find("input, textarea");
                    inputs.each(function (i, d) {
                        if ($(this).attr("name").toString() !== "_csrf" &&
                            $(this).attr("name").toString() !== "apply") {
                            $(this).val("");
                        }
                    });
                }, 2000);
            }
        });

        $(document).on("pjax:error", function(event, xhr, textStatus, errorThrown, options) {
            options.success(xhr.responseText, textStatus, xhr);
            return false;
        });
    }
}