/**
 * Created by jkav on 17.05.17.
 */

export class Slider {
    constructor() {
        let prevArrow = `<div class="custom-arrow-left">
                    <i class="fa fa-angle-left" aria-hidden="true"></i>
                </div>`;
        let nextArrow = `<div class="custom-arrow-right">
                    <i class="fa fa-angle-right" aria-hidden="true"></i>
                </div>`;

        let heroSliderClassName = ".hero-slider";
        if ($(heroSliderClassName).length > 0) {
            this.heroSlider(heroSliderClassName, nextArrow, prevArrow);
        }

        let rentSliderForClassName = ".rent-slider-for",
            rentSliderNavClassName = ".rent-slider-nav";
        if ($(rentSliderForClassName).length > 0 && $(rentSliderNavClassName).length > 0) {
            this.rentSlider(rentSliderForClassName, rentSliderNavClassName);
        }

        let rentSliderInterestedClassName = ".rent-maybe-interested-slider";
        if ($(rentSliderInterestedClassName).length > 0) {
            this.rentMaybeIntererstedSlider(rentSliderInterestedClassName, nextArrow, prevArrow);
        }
    };

    heroSlider(className: string, nextArrow: string, prevArrow: string): void {
        $(className).slick({
            dots: true,
            infinite: true,
            speed: 300,
            slidesToShow: 1,
            slidesToScroll: 1,
            adaptiveHeight: false,
            nextArrow: nextArrow,
            prevArrow: prevArrow
        });
        $(className).find("ul.slick-dots").find("li").wrapAll("<div class='container'>");
    };

    rentSlider(classNameFor: string, classNameNav: string): void {
        $(classNameFor).slick({
            slidesToShow: 1,
            slidesToScroll: 1,
            arrows: false,
            fade: true,
            dots: false,
            adaptiveHeight: false,
            asNavFor: ".rent-slider-nav"
        });
        $(classNameNav).slick({
            vertical: true,
            verticalSwiping: true,
            slidesToShow: 4,
            slidesToScroll: 4,
            asNavFor: ".rent-slider-for",
            dots: false,
            centerMode: false,
            focusOnSelect: true,
            adaptiveHeight: false,
            prevArrow: "<i class=\"fa fa-angle-up\" aria-hidden=\"true\"></i>",
            nextArrow: "<i class=\"fa fa-angle-down\" aria-hidden=\"true\"></i>"
        });
    };

    rentMaybeIntererstedSlider(className: string, nextArrow: string, prevArrow: string): void {
        $(className).slick({
            infinite: true,
            slidesToShow: 3,
            slidesToScroll: 3,
            adaptiveHeight: false,
            prevArrow: prevArrow,
            nextArrow: nextArrow
        });
    };
}
