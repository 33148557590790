import {AjaxGmap} from "../ajax/ajax-gmap";
import {Coordinate} from "./coordinate";
import {GmapControls} from "./gmapControls";
declare let MarkerClusterer: any;

declare let OverlappingMarkerSpiderfier: any;

declare global {
    interface Window { mapData: any; }
}

export class Gmap {
    private coordInit: Coordinate;
    private gmapControlsByDefault: GmapControls;
    private southampton: Coordinate;
    private paloAlto: Coordinate;

    private iconDanhousing: Object;
    private icon: string;
    private iconCluster: string;

    constructor () {
        this.coordInit = {id: undefined,
            latitude: undefined,
            longitude: undefined,
            geocode_error: 1,
            property: ""
        };

        this.southampton = {id: undefined,
            latitude: 40.159278,
            longitude: -75.018667,
            geocode_error: 0,
            property: "Southampton"
        };

        this.paloAlto = {id: undefined,
            latitude: 37.440583,
            longitude: -122.139806,
            geocode_error: 0,
            property: "Palo Alto"
        };

        this.gmapControlsByDefault = {
            streetViewControl: true,
            mapTypeControl: true,
            zoomControl: true,
            fullscreenControl: false
        };

        this.iconDanhousing = {
            url: "/assets/images/DANHousing-pin.png",
            // This marker is 20 pixels wide by 32 pixels high.
            // size: new google.maps.Size(20, 32),
            // The origin for this image is (0, 0).
            origin: new google.maps.Point(0, 0),
            // The anchor for this image is the base of the flagpole at (0, 32).
            anchor: new google.maps.Point(0, 65)
        };
        this.icon = "/assets/images/blue-pin.png";
        this.iconCluster = "/assets/images/cluster";
    };

    createMap (mapCanvas, coords, icon, zoom: number, gmapControls: GmapControls) {
        let minClusterZoom = 15;
        let centerUSALatitude = 39.801676;
        let centerUSALongitude = -96.921259;
        // if there are a lot of coordinates then center is in the center of USA, else center is the coordinate.
        let center;
        if (coords.length > 1) {
            center = new google.maps.LatLng(centerUSALatitude, centerUSALongitude);
        } else {
            center = new google.maps.LatLng(coords[0].latitude || centerUSALatitude,
                coords[0].longitude || centerUSALongitude);
        }
        let map = new google.maps.Map(document.getElementById(mapCanvas), {
            zoom: zoom,
            center: center,
            mapTypeId: google.maps.MapTypeId.ROADMAP,
            streetViewControl: gmapControls.streetViewControl,
            mapTypeControl: gmapControls.mapTypeControl,
            zoomControl: gmapControls.zoomControl,
            fullscreenControl: gmapControls.fullscreenControl
        });

        let markers = this.addMarkers(coords, icon, map);

        let options = {
            maxZoom: minClusterZoom,
            imagePath: this.iconCluster
        };

        let markerCluster = new MarkerClusterer(map, markers, options);
        google.maps.event.addListener(markerCluster, "clusterclick", function(cluster) {
            map.fitBounds(cluster.getBounds()); // Fit the bounds of the cluster clicked on
            if ( map.getZoom() > minClusterZoom + 1 ) {
                    // If zoomed in past 15 (first level without clustering), zoom out to 15
                map.setZoom(minClusterZoom + 1);
            }
        });
    };

    createMarker(coord, icon, map, infowindow, oms) {
        let marker = new google.maps.Marker({
            position: new google.maps.LatLng(coord.latitude,
                coord.longitude),
            map: map,
            icon: icon,
            title: coord.property
        });
        if (coord.id) {
            let contentString = `
                            <div>
                                <h5>${coord.property}</h5><hr/>
                                <div>
                                    <label>You can read about rent apartment by following link:</label><br/>
                                    <a href=\"/rent/view/?id=${coord.id}\" class="btn btn-link">
                                        ${coord.property}
                                    </a>
                                </div>
                            </div>`;
            oms.addMarker(marker, function(event) {
                infowindow.setContent(contentString);
                infowindow.open(map, marker);
            });
        }
        return marker
    };

    addMarkers (coords, icon, map) {
        let oms = new OverlappingMarkerSpiderfier(map);
        let markers = [];

        let infowindow = new google.maps.InfoWindow();
        google.maps.event.addListener(map, "click", () => { infowindow.close(); });
        for (let i = 0; i < coords.length; i++) {
            if (typeof coords[i].latitude === "undefined" ||
                coords[i].latitude === "") {
                continue;
            }
            markers.push(this.createMarker(coords[i], icon, map, infowindow, oms));
        }
        return markers;
    };

    private getInputElemById (id: string): string {
        let input = document.getElementById(id);
        return input ? (<HTMLInputElement> input).value : undefined;
    };

    /**
     * Parse string to int or float
     * @param value
     * @param type = float || int
     */
    private parseNumber(value: string, type: string = "float"): number  {
        let result: number = undefined;
        if (value && value !== "") {
            if (type === "float") {
                result = parseFloat(value);
            }
            if (type === "int") {
                result = parseInt(value);
            }
        }
        return result;
    };

    private parseNumberStrById(id: string, type: string = "float"): number {
        return this.parseNumber(this.getInputElemById(id));
    };

    static initRentMap (gmap: Gmap) {
        gmap = gmap || new Gmap();
        document.querySelector("a[data-target='#rent_map']")
            .addEventListener("click",
                () => {
                    AjaxGmap.getRentCoordsArray().then((data) => {
                        gmap.createMap("rent_map", data, gmap.icon, 5, gmap.gmapControlsByDefault);
                    });
                });
    };

    static init () {
        let gmap = new Gmap();
        // The map is in the contact-map section
        if ($("#map-canvas").length > 0) {
            let canvaseName = "map-canvas";
            let zoomMap = 14;
            gmap.createMap(canvaseName, [gmap.southampton], gmap.iconDanhousing, zoomMap, gmap.gmapControlsByDefault);
            document.querySelector("#southampton").addEventListener("click",
                () => {
                    gmap.createMap(canvaseName, [gmap.southampton], gmap.iconDanhousing, zoomMap,
                        gmap.gmapControlsByDefault);
                });
            document.querySelector("#paloAlto").addEventListener("click",
                () => {
                    gmap.createMap(canvaseName, [gmap.paloAlto], gmap.iconDanhousing, zoomMap,
                        gmap.gmapControlsByDefault);
                });
        }
        // The map is in the rent/view page.
        if ($("#rent-view-map").length > 0) {
            let gmapControls: GmapControls = {};
            $.extend(gmapControls, gmap.gmapControlsByDefault);
            gmapControls.fullscreenControl = true;

            let coord: Coordinate = gmap.coordInit;
            coord.latitude = gmap.parseNumberStrById("rent-view-latitude");
            coord.longitude = gmap.parseNumberStrById("rent-view-longitude");
            coord.geocode_error = gmap.parseNumberStrById("rent-view-geocode-error");
            coord.property = gmap.getInputElemById("rent-view-property");
            gmap.createMap("rent-view-map", [coord], gmap.icon, 13, gmapControls);
        }
        // the map is in the Show Map section on rent/index page
        if ($("#rent_map").length > 0) {
            Gmap.initRentMap(gmap);
        }

        // the small maps in rent-card
        if ($(".image-wrapper .property-location").length > 0) {
            let partContainerId = "rent_map_";
            let propLocationLinks = document.querySelectorAll(".image-wrapper .property-location");
            for (let i = 0; i < propLocationLinks.length; i++ ) {
                propLocationLinks[i].addEventListener("click", (event) => {

                    let imgWrapper = $(propLocationLinks[i]).parent();
                    let img = imgWrapper.find("img");
                    let map = imgWrapper.find(".rent-map-wrapper");

                    if (img.hasClass("hide")) {
                        map.addClass("hide");
                        img.removeClass("hide");

                        $(propLocationLinks[i]).removeClass("photo-badge");
                        $(propLocationLinks[i]).find("span").removeClass("icon-photo").addClass("icon-small-blue-pin");
                    } else if (map.hasClass("hide")) {
                        img.addClass("hide");
                        map.removeClass("hide");

                        $(propLocationLinks[i]).addClass("photo-badge");
                        $(propLocationLinks[i]).find("span").removeClass("icon-small-blue-pin").addClass("icon-photo");


                        let gmapControls: GmapControls = {
                            streetViewControl: false,
                            mapTypeControl: false,
                            zoomControl: false,
                            fullscreenControl: false
                        };

                        let coord: Coordinate = gmap.coordInit;
                        let elementId = $(propLocationLinks[i]).data("id");
                        coord.latitude = $(propLocationLinks[i]).data("latitude");
                        coord.longitude = $(propLocationLinks[i]).data("longitude");
                        coord.geocode_error = $(propLocationLinks[i]).data("geocodeError");
                        coord.property = $(propLocationLinks[i]).data("property");
                        gmap.createMap(partContainerId + elementId, [coord], gmap.icon, 11, gmapControls);
                    }
                });
            }
        }
    }
}